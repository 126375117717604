import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = {inProgress: true, listings: [], featuredResultIds: [], newestResultIds: [], accessoriesResultIds: []}, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case "app/sdk/GAME_PAGE_FEATURED_SUCCESS":
      return {
        ...state,
        featuredResultIds: resultIds(payload),
      }
    case "app/sdk/GAME_PAGE_NEWEST_SUCCESS":
      return {
        ...state,
        newestResultIds: resultIds(payload),
      }
    case "app/sdk/GAME_PAGE_ACCESSORIES_SUCCESS":
      return {
        ...state,
        accessoriesResultIds: resultIds(payload),
      }
    default: 
      return state;
  }
}

const loadFeaturedListings = async (config, dispatch, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const listings = await sdk.listings.query({ 
    pub_listingType: 'listing-game',
    minStock: 1,
    page: 1,
    perPage: 10,
    sort: '-pub_featured',
    include: ['author', 'images'], 
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });

  const listingFields = config?.listing?.listingFields;
  const sanitizeConfig = { listingFields };

  dispatch(addMarketplaceEntities(listings, sanitizeConfig));

  return dispatch({ type: 'app/sdk/GAME_PAGE_FEATURED_SUCCESS', payload: listings.data });
}

const loadNewestListings = async (config, dispatch, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const listings = await sdk.listings.query({ 
    pub_listingType: 'listing-game',
    minStock: 1,
    page: 1,
    perPage: 10,
    sort: 'createdAt',
    include: ['author', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });

  const listingFields = config?.listing?.listingFields;
  const sanitizeConfig = { listingFields };

  dispatch(addMarketplaceEntities(listings, sanitizeConfig));

  return dispatch({ type: 'app/sdk/GAME_PAGE_NEWEST_SUCCESS', payload: listings.data });
}

const loadFeaturedAccessories = async (config, dispatch, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const listings = await sdk.listings.query({ 
    pub_listingType: 'listing-accessory',
    minStock: 1,
    page: 1,
    perPage: 10,
    sort: '-pub_featured',
    include: ['author', 'images'], 
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });

  const listingFields = config?.listing?.listingFields;
  const sanitizeConfig = { listingFields };

  dispatch(addMarketplaceEntities(listings, sanitizeConfig));

  return dispatch({ type: 'app/sdk/GAME_PAGE_ACCESSORIES_SUCCESS', payload: listings.data });
}

export const loadData = (params, search, config) => async (dispatch, state, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  loadFeaturedListings(config, dispatch, sdk);
  loadNewestListings(config, dispatch, sdk);
  loadFeaturedAccessories(config, dispatch, sdk);
};
