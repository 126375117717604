const contentfulLib = require('contentful');

import appSettings from '../../config/settings';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

const contentful = contentfulLib.createClient({
  space: appSettings.contentful.spaceId,
  accessToken: appSettings.contentful.accessToken
});

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = {inProgress: true,game: {}, listings: [], currentPageResultIds: []}, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case 'app/contentful/GAME_PAGE_LOADING':
      return {
        ...state,
        inProgress: true
      }
    case 'app/sdk/GAME_PAGE_LOADING':
      return {
        ...state,
        listingsInProgress: true
      }
    case "app/contentful/GAME_PAGE_SUCCESS":
      return {
        ...state,
        game: payload,
        inProgress: true,
      }
    case "app/sdk/GAME_PAGE_SUCCESS":
      return {
        ...state,
        currentPageResultIds: resultIds(payload),
        listingsInProgress: false,
      }
    default: 
      return state;
  }
}

export const loadData = (params, search, config) => async (dispatch, state, sdk)  => {
  dispatch({ type: 'app/contentful/GAME_PAGE_LOADING' });

  const game = await contentful.getEntries({content_type: 'game', 'fields.slug': params.gameSlug});
  if (game.total == 1) {

    dispatch({ type: 'app/contentful/GAME_PAGE_SUCCESS', payload: game });

    dispatch({ type: 'app/sdk/GAME_PAGE_LOADING'});

    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;

    const listings = await sdk.listings.query({ 
      minStock: 1,
      keywords: game.items[0].fields.title, 
      include: ['author', 'images'], 
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    });

    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };

    dispatch(addMarketplaceEntities(listings, sanitizeConfig));

    return dispatch({ type: 'app/sdk/GAME_PAGE_SUCCESS', payload: listings.data });
  } else {
    dispatch({ type: 'app/contentful/GAME_PAGE_NOT_FOUND' });
  }


};
          