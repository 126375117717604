const contentfulLib = require('contentful');

import appSettings from '../../config/settings';

const contentful = contentfulLib.createClient({
  space: appSettings.contentful.spaceId,
  accessToken: appSettings.contentful.accessToken
});

export default function reducer(state = {gameIndex: {}}, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case "app/contentful/GAME_INDEX_SUCCESS":
      return {
        ...state,
        gameIndex: payload
      }
    default: 
      return state;
  }
}

export const loadData = (params, search) => async dispatch  => {
  dispatch({ type: 'app/contentful/GAME_INDEX_LOADING' });

  let page = 0;
  if (search.match(/\?page=[1-9]+/g)) {
    page = parseInt(search.split('?page=')[1]) - 1;
  }

  const games = await contentful.getEntries({content_type: 'game', order: "fields.title", skip: page * 100});

  return dispatch({ type: 'app/contentful/GAME_INDEX_SUCCESS', payload: games });
};
          