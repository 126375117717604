
const contentfulLib = require('contentful');

import appSettings from '../../config/settings';

const contentful = contentfulLib.createClient({
  space: appSettings.contentful.spaceId,
  accessToken: appSettings.contentful.accessToken
});


export const SEARCH_GAME_STORES_REQUEST = 'app/SearchPage/SEARCH_GAME_STORES_REQUEST';
export const SEARCH_GAME_STORES_SUCCESS = 'app/SearchPage/SEARCH_GAME_STORES_SUCCESS';
export const SEARCH_GAME_STORES_ERROR = 'app/SearchPage/SEARCH_GAME_STORES_ERROR';

export default function reducer(state = {stores: {}}, action = {}) {
    const { type, payload } = action;
  
    switch (type) {
      case SEARCH_GAME_STORES_SUCCESS:
        return {
          ...state,
          stores: payload
        }
      default: 
        return state;
    }
  }

export const loadData = (params, search, config) => async (dispatch, state, sdk) => {
    dispatch({
      type: SEARCH_GAME_STORES_REQUEST
    });
  
    const stores = await contentful.getEntries({content_type: 'store', limit: 1000});
  
    return dispatch({
      type: SEARCH_GAME_STORES_SUCCESS,
      payload: { stores },
    })
  }